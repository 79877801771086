// Import Icons
import NewsImage from '../../../assets/Images/Mor_datter_nyhed.jpg';
import NewsImageMobile from '../../../assets/Images/newsimageMobile.png';
import BannerImage from '../../../assets/Images/image-skilsmisse.png';
// TempData
import TempDataNavBar from '../TempData_NavBar';
import TempDataMegaMenu from '../TempData_MegaMenu';
import TempDataFooter from '../TempData_footer';

// Page Data
const Data = {
  pageName: 'NewsModule Test Page',
  pageTemplate: 'Template3',
  pageData: {
    nav: TempDataNavBar,
    megaMenu: TempDataMegaMenu,
    footer: TempDataFooter,
    level_1_Id: 'Page_1',
    pageTitle: 'HTML render test page',
    pageDescription: 'Tester component - HTML render',
    bannerImage: BannerImage, // accepts null
    bannerContent: '<h1>Banner text accepts HTML</h1>', // accepts null
    bottomContent: null,
    desktopTools: {}, // accepts null
  },
  pageContent: [
    {
      componentName: 'NewsCard',
      componentData: {
        id: 1,
        image: NewsImage,
        imageMobile: NewsImageMobile,
        imageAltText: 'News Image',
        timestamp: '12.10.2018',
        header:
          'Få overblik over din pension,Få overblik over din pension,Få overblik over din pension',
        text:
          'Dan dig nemt og hurtigt et overblik over din pension og se hvor godt du er stillet i forhold til din pension,Dan dig nemt og hurtigt et overblik over din pension og se hvor godt du er stillet i forhold til din pension,Dan dig nemt og hurtigt et overblik over din pension og se hvor godt du er stillet i forhold til din pension',
        url: '/item1',
      },
    },
    {
      componentName: 'NewsCard',
      componentData: {
        id: 2,
        image: '',
        imageAltText: '',
        timestamp: '12.10.2018',
        header: 'Få overblik over din pension',
        text:
          'Dan dig nemt og hurtigt et overblik over din pension og se hvor godt du er stillet i forhold til din pension',
        url: '/item1',
      },
    },
    {
      componentName: 'NewsCard',
      componentData: {
        id: 3,
        image: NewsImage,
        imageMobile: NewsImageMobile,
        imageAltText: 'News Image',
        timestamp: '12.10.2018',
        header: 'Få overblik over din pension',
        text:
          'Dan dig nemt og hurtigt et overblik over din pension og se hvor godt du er stillet i forhold til din pension',
        url: '/item1',
      },
    },
    {
      componentName: 'NewsCard',
      componentData: {
        id: 4,
        image: '',
        imageAltText: '',
        timestamp: '12.10.2018',
        header: 'Få overblik over din pension',
        text:
          'Dan dig nemt og hurtigt et overblik over din pension og se hvor godt du er stillet i forhold til din pension',
        url: '/item1',
      },
    },
    {
      componentName: 'NewsCard',
      componentData: {
        id: 5,
        image: NewsImage,
        imageMobile: NewsImageMobile,
        imageAltText: 'News Image',
        timestamp: '12.10.2018',
        header: 'Få overblik over din pension',
        text:
          'Dan dig nemt og hurtigt et overblik over din pension og se hvor godt du er stillet i forhold til din pension',
        url: '/item1',
      },
    },
    {
      componentName: 'NewsCard',
      componentData: {
        id: 6,
        image: '',
        imageAltText: '',
        timestamp: '12.10.2018',
        header: 'Få overblik over din pension',
        text:
          'Dan dig nemt og hurtigt et overblik over din pension og se hvor godt du er stillet i forhold til din pension',
        url: '/item1',
      },
    },
  ],
};

export default Data;
