// Import Node Modules
import React from 'react';

// renderPage
import { renderPage } from '../../template/RenderPage';

// TempData
import Data from '../../mocks/pages/PageData/NewsModule_data';

// Class
const NewsModule = () => <div>{renderPage(Data)}</div>;

export default NewsModule;
